<template>
  <div class="container">
    <div class="columns">
      <div class="column">
        <v-client-table
          ref="payments"
          :columns="tableColumns"
          :options="tableOptions"
          :data="details"
          class="is-align-middle"
        >
          <template slot="afterLimit">
            <download-buttons
              @clicked="downloadFile"
            />
          </template>
          <template
            slot="amount"
            slot-scope="props"
          >
            ${{ $helpers.formatMoney(props.row.amount) }}
          </template>
          <template
            slot="status"
            slot-scope="props"
          >
            <strong>{{ props.row.status }}</strong>
          </template>
        </v-client-table>
        <p>The invoice number is contained in the description field, between either asterisks or pound signs. For example, *12345* or #12345#.</p>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadButtons from "@/components/DownloadButtons";

import { options } from "@/config/tableOptions";

export default {
  components: {
    DownloadButtons,
  },
  props: {
    details: {
      type: [ Array ],
      default: () => [],
    },
    paymentDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableColumns: [
        "amount",
        "description",
        "purchaseOrderNumber",
        "voucherNumber",
        "checkNum",
        "departmentName",
      ],
      tableOptions: options({
        headings: {
          purchaseOrderNumber: "Purchase\norder #",
          voucherNumber: "Payment\nVoucher #",
          checkNum: "Check #/ACH #",
          departmentName: "Department",
        },
        orderBy: {
          ascending: true,
          column: "paymentDueDate",
        },
        dateColumns: [ 'paymentDueDate' ],
        dateFormat: "MMMM DD, YYYY",
        columnsClasses: {
          amount: 'amount',
        },
        perPageValues: [],
        resizableColumns: true,
      }),
    };
  },
  beforeMount() {
    if (this.details.length < 5) {
      this.tableOptions.filterable = [];
      this.tableOptions.sortable = [];
    }
  },
  methods: {
    downloadFile(type) {
      const fileName = `Payment-Details-${this.paymentDate}`;

      /**
       * Prepare Object to Download
       */
      let data = this.details.map(payment => [
        this.$helpers.formatMoney(String(payment.amount)),
        String(payment.description),
        String(payment.purchaseOrderNumber),
        String(payment.voucherNumber),
        String(payment.checkNum),
        String(payment.departmentName),
      ]);

      if (data.length === 0) {
        this.$warning(`There is not data to export...`);
        return;
      }

      data.unshift([
        "Amount",
        "Description",
        "Purchase\nOrder #",
        "Payment\nVoucher #",
        "Check # /\nACH #",
        "Department",
      ]);

      if (type === 'csv') {
        this.$helpers.downloadCSV(data, fileName);
      } else {
        this.$helpers.downloadPDF(
          data,
          fileName,
          `Payment Details ${this.paymentDate}`,
          {
            0: { cellWidth: 'wrap' },
            2: { cellWidth: 'wrap' },
            3: { cellWidth: 'wrap' },
            4: { cellWidth: 'wrap' },
            5: { cellWidth: 'wrap' },
          },
          {
            cellWidth: 'wrap',
          },
        );
      }

      data = null;
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="columns">
      <div class="column is-paddingless">
        <payments-tabs />
        <div class="tabs-container content fix-margin">
          <div class="container">
            <div class="columns">
              <div class="column is-12">
                <h2>Payments made</h2>
                <p>View or download details for completed payments from the City</p>
                <div
                  class="fix-table-filter-message"
                  :class="{ 'hide-edges': hideEdges }"
                >
                  <v-client-table
                    ref="payments"
                    :columns="tableColumns"
                    :options="tableOptions"
                    :data="tableValues"
                  >
                    <template slot="afterLimit">
                      <download-buttons
                        @clicked="downloadFile"
                      />
                    </template>
                    <template slot="afterFilter">
                      <div class="filter-info">
                        <p>Search by date, amount, or invoice number.</p>
                      </div>
                    </template>
                    <template
                      slot="amount"
                      slot-scope="props"
                    >
                      ${{ $helpers.formatMoney(props.row.amount) }}
                    </template>
                    <template
                      slot="date"
                      slot-scope="props"
                    >
                      {{ props.row.date }}
                    </template>
                    <template
                      slot="actions"
                      slot-scope="props"
                    >
                      <a @click.prevent="displayInfo(props.row)">Payment details</a>
                    </template>
                  </v-client-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <modal
          ref="detailsModal"
          class="is-large"
          :settings="modalSettings"
        >
          <payment-details
            :details="details"
            :payment-date="paymentDate"
          />
        </modal>
      </div>
    </div>
  </div>
</template>
<script>
import PaymentsTabs from "@/components/PaymentsTabs";
import Modal from "@/components/common/Modal";
import PaymentDetails from "@/components/PaymentDetails";
import DownloadButtons from "@/components/DownloadButtons";

import { options } from '@/config/tableOptions';
const tableColumns = [
  "date",
  "amount",
  "description",
  "actions",
];

export default {
  components: {
    PaymentsTabs,
    PaymentDetails,
    Modal,
    DownloadButtons,
  },
  data() {
    return {
      modalSettings: {
        title: 'Payment details',
        buttons: [],
      },
      details: [],
      paymentDate: [],
      tableColumns,
      tableOptions: options({
        hiddenColumns: [
          "description",
        ],
        skin: 'VueTables__table is-align-middle table is-bordered is-striped is-hoverable is-fullwidth',
        headings: {
          date: "Payment date",
          checkNum: "Check #",
          actions: "Payment details link",
        },
        columnsClasses: {
          amount: 'amount',
        },
        dateFormat: 'MM/DD/YYYY',
        orderBy: {
          ascending: true,
          column: "paymentDueDate",
        },
        sortable: [ 'amount', 'date' ],
        resizableColumns: true,
      }),
    };
  },
  computed: {
    tableValues() {
      return this.$store.state.vendors.paidPayments || [];
    },
  },
  async fetch({ store, params }) {
    let vendor = params.id;
    if (params.id === 'own') {
      vendor = null;
    }

    await store.dispatch('vendors/getPaidPayments', vendor);
    return Promise.resolve();
  },
  methods: {
    async displayInfo(row) {
      const data = await this.$store.dispatch('vendors/getPaymentDetails', {
        uuid: this.$route.params.id !== 'own' ? this.$route.params.id : '',
        date: this.$dayjs(row.date).format('YYYY-MM-DD'),
      });
      if (data) {
        this.paymentDate = row.date;
        this.details = data;
        this.$refs.detailsModal.show();
      }
      return false;
    },
    downloadFile(type) {
      const fileName = `Payments-Made-${this.$dayjs().format('YYYY-MM-DD-HH-mm')}`;

      /**
       * Prepare Object to Download
       */
      let data = this.tableValues.map(payment => [
        String(payment.date.format('MMMM DD, YYYY')), // ('MM/DD/YYYY HH:mm'),
        this.$helpers.formatMoney(String(payment.amount)),
      ]);

      if (data.length === 0) {
        this.$warning(`There is not data to export...`);
        return;
      }

      data.unshift([ 'Date', 'Amount' ]);


      if (type === 'csv') {
        this.$helpers.downloadCSV(data, fileName);
      } else {
        this.$helpers.downloadPDF(
          data,
          fileName,
          'Payments Made'
        );
      }

      data = null;
    },
  },
};
</script>
<style lang="scss">
  .table.selectable {
    tr {
      cursor: pointer;
    }
  }
</style>
<style lang="scss">
  .fix-table-filter-message {
    .VueTables__search {
      flex-direction: column;
      .filter-info {
        position: relative;
        p {
          font-size: 0.857rem;
          margin-top: 5px;
          font-weight: 400;
          color: $ben-franklin-blue-dark;
        }
      }
      .VueTables__search-field {
        position: relative;
      }
    }
  }
</style>
